/**
 * Filter for config file.
 *
 * @name Filter
 * @class
 * @public
 */
export default class Filter {
  /**
   * @param {string} key Unique key for identification filter.
   * @param {string} name Filter name or label using to show on pill.
   * @param {string} value Value to send to backend.
   * For example if value is'user.name' it will be sent to api request like this 'filter['user.name']=xxxxx'
   * @param {string} textBy If filter has meta value, it will be using this property to get a value to show on pill. Default 'name'.
   * @param {string} trackBy If filter has meta value, it will be using this property to get a value of meta and set url and get from service. Default 'id'.
   * @constructor
   */
  constructor(key, name, value, textBy = 'name', trackBy = 'id') {
    /** @readonly @type {string} Unique key for identification filter. */
    this.key = key;
    /** @readonly @type{string} Filter name or label using to show on pill. */
    this.name = name;
    /**
     * @readonly @type {string} Value to send to backend.
     * @example
     * if value is'user.name'
     * it will be sent to api request like this 'filter['user.name']=xxxxx'
     */
    this.value = value;

    /** @readonly @type {string[]} List of roles id can be shown this filter */
    this.allowedRoles = [];

    /** @readonly @type {string[]} List of breakdown */
    this.breakdown = [];

    /** @type {any[]} List of options to show on filters. This is optional. */
    this.options = [];

    // New feature
    /** @readonly @type {string} Property name to get value to put on url filter and using  to getItemBy function. */
    this.trackBy = trackBy;
    /** @readonly @type {string} Property name to get value to show on pill. */
    this.textBy = textBy;
    /** @readonly @type {boolean} Set it to true if you want apply multiple filter like this. Default is false. */
    this.multiple = false;

    /** @private @type {function} Get options from endpoint. */
    this.service = undefined;
    /** @private @type {function} Get item by from endpoint. */
    this._getItemBy = undefined;

    /** @readonly @type {boolean} Set it to true if you want apply multiple filters at the same time. Default is false. */
    this.hasSeveralValues = false;
  }

  /**
   * Add multiple filters like this. equal key.
   * @returns Filter
   */
  asMultiple() {
    this.multiple = true;
    return this;
  }

  /**
   * Add only a filter like this. equal key.
   * @returns Filter
   */
  asSimple() {
    this.multiple = false;
    return this;
  }

  /**
   * Any async function or service to get a list of options to set filters options.
   * If you need to load options from endpoint using asteix-async-select it is
   * better than this option.
   *
   * @callback getOptionsFunction
   * @return {Array<any>} return array of options
   *
   * @param {Array<any>|getOptionsFunction} optionsOrGetOptions List of filters options or Service to get options from endpoint.
   * @returns Filter
   *
   * @example
   * new Filter(...).setOptions(['SIMPLE','MULTIPLE']);
   *
   * new Filter(...).setOptions([{id:1,text:'SIMPLE'}, {id:2,text:'MULTIPLE'}]);
   *
   * const getCustomOptions = async () => [{id:1,text:'SIMPLE'}, {id:2,text:'MULTIPLE'}];
   * new Filter(...).setOptions(getCustomOptions);
   */
  setOptions(optionsOrGetOptions) {
    if (typeof optionsOrGetOptions !== 'function') {
      this.options = optionsOrGetOptions;
      this.service = undefined;
      this.getItemBy(async selectedOption => this.options.find(opt => opt[this.trackBy] === selectedOption));
    } else {
      this.service = optionsOrGetOptions;
      this.options = undefined;
    }
    return this;
  }

  /**
   * @param {...string} allowedRoles List of roles id can be shown this filter.
   * @returns Filter
   */
  setAllowedRoles(...allowedRoles) {
    this.allowedRoles = allowedRoles.flatMap(roleId => roleId.id || roleId);
    return this;
  }

  /**
   * @param {import('./roles').Role|string} rol Rol id
   * @returns boolean Return true if this rol exit on allowed roles list or if roles list is empty else false
   */
  isRolAllowed(rol) {
    return !this.allowedRoles.length || this.allowedRoles.includes(rol?.id || rol);
  }

  /**
   * @param {...string} breakdown List of breakdown
   * @returns Filter
   */
  setBreakdown(...breakdown) {
    this.breakdown = breakdown;
    return this;
  }

  /**
   * @param {string} breakdown
   * @returns boolean Return true if this breakdown exit on breakdown list or breakdown lis is empty else false
   */
  isBreakdownAllowed(breakdown) {
    return !this.breakdown.length || this.breakdown.includes(breakdown);
  }

  /**
   * @callback callback
   * @param {string} value filter selected value, normally is a id
   * @return {object} return a element
   *
   * @param {callback} callback set service to get options from endpoint or any
   * async function to transform selected value.
   * @returns Filter
   */
  getItemBy(callback) {
    if (typeof callback !== 'function') {
      throw new Error('Callback must be a function');
    }
    this._getItemBy = callback;
    return this;
  }

  /**
   * Return filter's name.
   * @returns string
   */
  toString() {
    return this.name;
  }

  allowSeveralValues() {
    this.hasSeveralValues = true;
    return this;
  }
}
